
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import * as XLSX from "xlsx";

interface XlsColumn {
  number: number;
  key: string;
  internalKey: string; // When changing internalKey values, validation rules might need update
  type: "integer" | "currency" | "boolean" | "nonReconciliationMotive";
  required: boolean;
}

export type ProcessedData = Array<{
  loanId: number;
  reconciliationValue: number;
  reconciliate: boolean;
  nonReconciliationMotive: string;
  severancePayValue: number;
}>;

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal
  }
})
export default class BatchesManageImportFileModal extends Vue {
  @Prop({ default: false }) show: boolean;
  @Prop() batchId!: number;

  templateDownloadUrl = "/files/Modelo-arquivo-repasse-Gooroo-Credito.xlsx";
  xlsColumns: Array<XlsColumn> = [
    {
      number: 1,
      key: "*PROPOSTA",
      internalKey: "loanId",
      type: "integer",
      required: true
    },
    {
      number: 2,
      key: "*VALOR_REPASSE",
      internalKey: "reconciliationValue",
      type: "currency",
      required: true
    },
    {
      number: 3,
      key: "*CONSIGNA",
      type: "boolean",
      internalKey: "reconciliate",
      required: true
    },
    {
      number: 4,
      key: "MOTIVO",
      type: "nonReconciliationMotive",
      internalKey: "nonReconciliationMotive",
      required: false
    },
    {
      number: 5,
      key: "VERBAS_RESCISORIAS",
      type: "currency",
      internalKey: "severancePayValue",
      required: false
    }
  ];
  acceptedNonReconciliationMotives = [
    "FUNCIONARIO_DESLIGADO",
    "FUNCIONARIO_AFASTADO",
    "FUNCIONARIO_SEM_MARGEM"
  ];
  form = {
    file: null
  };
  errors = [];
  loadingImportFile = false;

  readFile(): void {
    this.loadingImportFile = true;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const processedData = this.processData(jsonData as any[][]);
      if (!processedData) {
        return;
      }
      this.$emit("processFile", processedData);
      this.$notify({
        type: "success",
        title: "Arquivo importado com sucesso"
      });
      this.close();
    };
    fileReader.readAsArrayBuffer(this.form.file);
    this.loadingImportFile = false;
  }

  processData(jsonData: any[][]): ProcessedData | undefined {
    // Validate headers
    const headers = jsonData[0];
    const isValidHeaders = this.xlsColumns.every(
      (column) => headers[column.number - 1] === column.key
    );
    if (!isValidHeaders) {
      this.$notify({
        type: "error",
        title: "Erro ao importar arquivo",
        text: "O arquivo selecionado não possui as colunas esperadas. Baixe o modelo e tente novamente."
      });
      return;
    }

    const throwValidationError = (
      rowNumber: number,
      columnKey: string,
      extraMessage?: string
    ) => {
      this.$notify({
        type: "error",
        title: "Erro ao importar arquivo",
        text: `O arquivo importado contém dados inválidos. Verifique a linha ${rowNumber}, coluna ${columnKey}. ${
          extraMessage ? `<br/><br/>${extraMessage}` : ""
        }`,
        duration: 10000
      });
    };

    const dataRows = jsonData.slice(1);
    let rowNumber = 1;

    // Validate and process data

    const parseField = (column: XlsColumn, cellValue: any): any => {
      let normalizedValue = cellValue;

      if (
        normalizedValue !== undefined &&
        normalizedValue !== null &&
        normalizedValue !== ""
      ) {
        if (column.type === "integer") {
          normalizedValue = Number(cellValue);
        }

        if (column.type === "currency") {
          normalizedValue = Number(Number(cellValue).toFixed(2));
        }

        if (column.type === "boolean") {
          normalizedValue = String(cellValue).trim().toUpperCase();
        }

        if (column.type === "nonReconciliationMotive") {
          normalizedValue = String(cellValue).trim().toUpperCase();
        }
      }

      return normalizedValue;
    };

    const processedData: ProcessedData = [];

    for (const row of dataRows) {
      rowNumber++;

      const rowIsEmpty = !this.xlsColumns.some(
        (column) => row[column.number - 1] !== undefined
      );

      if (rowIsEmpty) {
        continue;
      }

      const getValueForColumn = (internalKey: string): any | undefined =>
        parseField(
          this.xlsColumns.find((column) => column.internalKey === internalKey),
          row.find(
            (_, index) =>
              this.xlsColumns.find((column) => column.number === index + 1)
                .internalKey === internalKey
          )
        );

      for (const column of this.xlsColumns) {
        let cellValue = row[column.number - 1];

        // Check required field
        if (
          column.required &&
          (cellValue === undefined || cellValue === null || cellValue === "")
        ) {
          throwValidationError(rowNumber, column.key, "Campo obrigatório");
          return;
        }

        // Normalize field
        cellValue = parseField(column, cellValue);

        // Check data types
        if (cellValue !== undefined && cellValue !== null && cellValue !== "") {
          if (column.type === "integer" && !Number.isInteger(cellValue)) {
            throwValidationError(
              rowNumber,
              column.key,
              "Valor deve ser um número inteiro"
            );
            return;
          }

          if (column.type === "currency" && isNaN(cellValue)) {
            throwValidationError(
              rowNumber,
              column.key,
              "Valor deve ser um número"
            );
            return;
          }

          if (
            column.type === "boolean" &&
            cellValue !== "SIM" &&
            cellValue !== "NAO"
          ) {
            throwValidationError(
              rowNumber,
              column.key,
              'Valores aceitos: "SIM", "NAO"'
            );
            return;
          }

          if (
            column.type === "nonReconciliationMotive" &&
            !this.acceptedNonReconciliationMotives.includes(cellValue)
          ) {
            throwValidationError(
              rowNumber,
              column.key,
              'Motivos aceitos: "FUNCIONARIO_DESLIGADO", "FUNCIONARIO_AFASTADO", "FUNCIONARIO_SEM_MARGEM"'
            );
            return;
          }
        }

        // Special rule: If reconciliate is SIM, reconciliationValue must be greater than or equal to 0.01
        if (
          column.internalKey === "reconciliate" &&
          cellValue === "SIM" &&
          getValueForColumn("reconciliationValue") < 0.01
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "CONSIGNA" é "SIM", "VALOR_REPASSE" deve ser pelo menos 0,01.'
          );
          return;
        }

        // Special rule: If reconciliate is NAO, reconciliationValue must be zero
        if (
          column.internalKey === "reconciliate" &&
          cellValue === "NAO" &&
          getValueForColumn("reconciliationValue") !== 0
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "CONSIGNA" é "NAO", "VALOR_REPASSE" deve ser 0.'
          );
          return;
        }

        // Special rule: If reconciliate is NAO, nonReconciliationMotive is required
        if (
          column.internalKey === "reconciliate" &&
          cellValue === "NAO" &&
          !getValueForColumn("nonReconciliationMotive")
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "CONSIGNA" é "NAO", "MOTIVO" é obrigatório.'
          );
          return;
        }

        // Special rule: If nonReconciliationMotive is FUNCIONARIO_DESLIGADO, severancePayValue is required
        if (
          column.internalKey === "nonReconciliationMotive" &&
          cellValue === "FUNCIONARIO_DESLIGADO" &&
          !getValueForColumn("severancePayValue")
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "MOTIVO" é "FUNCIONARIO_DESLIGADO", "VERBAS_RESCISORIAS" é obrigatório.'
          );
          return;
        }

        // Special rule: If nonReconciliationMotive is not FUNCIONARIO_DESLIGADO, severancePayValue must not be filled
        if (
          column.internalKey === "nonReconciliationMotive" &&
          cellValue !== "FUNCIONARIO_DESLIGADO" &&
          getValueForColumn("severancePayValue")
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "MOTIVO" não é "FUNCIONARIO_DESLIGADO", "VERBAS_RESCISORIAS" não deve ser preenchido.'
          );
          return;
        }

        // Special rule: If reconciliate is SIM, nonReconciliationMotive and severancePayValue must not be filled
        if (
          column.internalKey === "reconciliate" &&
          cellValue === "SIM" &&
          (getValueForColumn("nonReconciliationMotive") ||
            getValueForColumn("severancePayValue"))
        ) {
          throwValidationError(
            rowNumber,
            column.key,
            'Quando "CONSIGNA" é "SIM", "MOTIVO" e "VERBAS_RESCISORIAS" não devem ser preenchidos.'
          );
          return;
        }
      }

      const processedRow = {
        loanId: getValueForColumn("loanId"),
        reconciliationValue: getValueForColumn("reconciliationValue"),
        reconciliate: getValueForColumn("reconciliate") === "SIM",
        nonReconciliationMotive: getValueForColumn("nonReconciliationMotive"),
        severancePayValue: getValueForColumn("severancePayValue")
      };
      processedData.push(processedRow);
    }

    return processedData;
  }

  close(): void {
    this.form.file = null;
    this.$emit("close");
  }
}
